import React, { useState } from 'react';

// Hook to manage expandable content
export const useExpandableContent = () => {
  const [expandedContent, setExpandedContent] = useState({});

  const toggleContent = (itemId, contentType) => {
    setExpandedContent((prev) => {
      const currentState = prev[itemId] || '';
      return {
        ...prev,
        [itemId]: currentState === contentType ? '' : contentType,
      };
    });
  };

  return { expandedContent, toggleContent };
};

export const DropdownTrigger = ({ children, onClick, isActive = false }) => (
    <button
      onClick={onClick}
      className={`
        inline-flex items-center 
        font-sans
        text-body
      `}
    >
    <span>{children}</span>
    <svg
      className={`w-4 h-4 ml-1 mr-4 transition-transform ${
        isActive ? 'rotate-180' : ''
      }`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  </button>
);

export const LinkWithIcon = ({ children, href, icon, linkStyle = 'subtle' }) => {
  const linkClasses = {
    prominent: 'link-prominent',
    important: 'link-important',
    subtle: 'link-subtle inline-flex items-center', // Ensure subtle links match your current research page style
  };

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`
        ${linkClasses[linkStyle]} 
        font-serif transition-colors
      `}
    >
      <span>{children}</span> {/* Display link text */}
      {icon && <span className="ml-2">{icon}</span>} {/* Display icon if provided */}
    </a>
  );
};