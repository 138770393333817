export const articles = [
  {
    id: 'carry_trade',
    title: 'Who Carries?',
    paperUrl: '/documents/research/ferreira-leon-ledesma-mullen-who-carries-portfolio-choice-heterogeneity-paper.pdf',
    status: 'Working Paper',
    image: {
      src: '/figures/research/202101_carry_trade/g7_vs_row_scatter/g7_vs_row_scatter_gray.svg',
      srcColor: '/figures/research/202101_carry_trade/g7_vs_row_scatter/g7_vs_row_scatter_color.svg'
    },
    coAuthors: [
      {
        name: 'Alex Ferreira',
        url: 'https://sites.google.com/usp.br/alexferreira/home'
      },
      {
        name: 'Giuliano Ferreira',
        url: 'https://br.linkedin.com/in/giuliano-de-queiroz-ferreira-4448b415'
      },
      {
        name: 'Miguel León-Ledesma',
        url: 'https://sites.google.com/site/miguelleonledesmaspernonalsite/Home'
      }
    ],
    abstract: 'In representative agent models, consumption growth risk can only explain currency carry trade returns when risk aversion is implausibly high. However, a highly risk-averse representative agent will not hold a carry trade portfolio. Heterogeneity helps by allowing a risk-tolerant minority of agents to hold carry trade portfolios while a risk-intolerant majority does not. With heterogeneity in risk aversion, we prove that otherwise standard international macroeconomic model can produce carry traders in low-interest economies together with domestic bias in aggregate portfolios, just as we observe in the data for G7 economies.',
    bibtex: `@article{Ferreira2025CarryTrade,
        title={Who Carries?},
        author={Alex Ferreira and Giuliano Ferreira and Miguel León-Ledesma and Rory Mullen},
        journal={Working Paper},
        year={2025}
}`,
    links: [
      {
        name: 'Appendix',
        url: '/documents/research/ferreira-leon-ledesma-mullen-who-carries-portfolio-choice-heterogeneity-appendix.pdf',
        icon: 'external'
      },
      {
        name: 'Slides',
        url: '/documents/research/ferreira-leon-ledesma-mullen-who-carries-portfolio-choice-heterogeneity-slides.pdf',
        icon: 'external'
      },
      {
        name: 'SSRN',
        url: 'https://papers.ssrn.com/sol3/papers.cfm?abstract_id=5168668',
        icon: 'external'
      }
    ]
  },
  {
    id: 'fx-intervention',
    title: 'Foreign Exchange Interventions and Intermediary Constraints',
    paperUrl: '/documents/research/ferreira-mullen-ricco-viswanath-natraj-wang-foreign-exchange-intervention-central-bank-paper.pdf',
    status: 'Working Paper',
    image: {
      src: '/figures/research/202107_fx_intervention/thresholds_dist/threshold_dist_gray.svg',
      srcColor: '/figures/research/202107_fx_intervention/thresholds_dist/threshold_dist_color.svg'
    },
    coAuthors: [
      {
        name: 'Alex Ferreira',
        url: 'https://sites.google.com/usp.br/alexferreira/home'
      },
      {
        name: 'Giovanni Ricco',
        url: 'https://www.giovanni-ricco.com'
      },
      {
        name: 'Ganesh Viswanath-Natraj',
        url: 'https://ganeshvnatraj.netlify.app'
      },
      {
        name: 'Zijie Wang',
        url: 'https://zijie-cj-wang.github.io/home/'
      }
    ],
    abstract: 'We study the impact of foreign exchange interventions during periods of tight credit constraints. Expanding on the Gabaix and Maggiori (2015) model, we predict that long-lived spot interventions have larger effects on exchange rates than short-lived swaps, unanticipated interventions are more impactful, and tighter credit constraints amplify effects. Using high-frequency data on Brazilian Central Bank interventions from 1999 to 2023, we find that unanticipated spot sales of USD reserves lead to significant domestic currency appreciation and reduced covered interest parity deviations. Spot interventions outperform swaps, especially when global intermediaries are constrained, and enhance market efficiency by lowering USD borrowing costs.',
    bibtex: `@article{Ferreira2025FXIntervention,
        title={Foreign Exchange Interventions and Intermediary Constraints},
        author={Alex Ferreira and Rory Mullen and Giovanni Ricco and Ganesh Viswanath-Natraj and Zijie Wang},
        journal={Working Paper},
        year={2025}
}`,
    links: [
      {
        name: 'Appendix',
        url: '/documents/research/ferreira-mullen-ricco-viswanath-natraj-wang-foreign-exchange-intervention-central-bank-appendix.pdf',
        icon: 'external'
      },
      {
        name: 'Slides',
        url: '/documents/research/ferreira-mullen-ricco-viswanath-natraj-wang-foreign-exchange-intervention-central-bank-slides.pdf',
        icon: 'external'
      },
      {
        name: 'SSRN',
        url: 'https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4976307',
        icon: 'external'
      }
    ]
  },
  {
    id: 'aggregate-fluctuations',
    title: 'On Aggregate Fluctuations, Systemic Risk, and the Covariance of Firm-Level Activity',
    paperUrl: '/documents/research/mullen-aggregate-fluctuations-systemic-risk-paper.pdf',
    status: 'Working Paper',
    image: {
      src: '/figures/research/201610_vintagecapital/tech_choice/tech_choice_gray.svg',
      srcColor: '/figures/research/201610_vintagecapital/tech_choice/tech_choice_color.svg'
    },
    abstract: 'Pairwise covariances of firm growth rates appear to drive the variance of aggregate growth rates in productivity, sales, and profit for public firms in the United States over the last half-century. High-productivity firms contribute most to the covariances driving aggregate variance, but least per dollar of market value that they generate. This fact may explain why investors demand lower returns from high-productivity firms. A tractable model of within-firm diversification qualitatively matches the empirical evidence, generating endogenous first and second moments of firm and aggregate productivity, and endogenous comovement between firm and aggregate productivity. In the model, movements in firm productivity drive movements in firm sales and profit, and firms\' expected excess stock returns rise as firms\' productivities covary more with aggregate productivity, relative to their market values. A regression analysis lends tentative empirical support to several predictions of the model.',
    bibtex: `@article{Mullen2025AggregateFluctuations,
        title={On Aggregate Fluctuations, Systemic Risk, and the Covariance of Firm-Level Activity},
        author={Rory Mullen},
        journal={Working Paper},
        year={2025}
}`,
    links: [
      {
        name: 'Appendix',
        url: '/documents/research/mullen-aggregate-fluctuations-systemic-risk-appendix.pdf',
        icon: 'external'
      },
      {
        name: 'Slides',
        url: '/documents/research/mullen-aggregate-fluctuations-systemic-risk-slides.pdf',
        icon: 'external'
      },
      {
        name: 'SSRN',
        url: 'https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4510357',
        icon: 'external'
      }
    ]
  },
  {
    id: 'techtext',
    title: 'Firm Technology Usage',
    paperUrl: '',
    status: 'Work in Progress',
    image: {
      src: '',
      srcColor: ''
    },
    coAuthors: [
      {
        name: 'Jesus Gorrin',
        url: 'https://www.jesusgorrin.com'
      }
    ],
    abstract: 'Technology is central to economics, but current technology datasets fail to meet the needs of economists because they lack scale, scope, span, or specificity. We apply natural language processing and positive and unlabeled machine learning to patent descriptions and business descriptions from U.S. public firms over three decades to produce a firm-level technology dataset that offers an unrivaled combination of these characteristics. We identify the core technologies of patent non-owning firms, estimate the value to these firms of innovations to the core technologies they use, and show how stock market participants inefficiently process technological information on these firms, leading to profitable trading strategies.',
    bibtex: ``,
    links: []
  },
  {
    id: 'comprisk',
    title: 'Investment Completion Risk and Stock Returns',
    paperUrl: '',
    status: 'Work in Progress',
    image: {
      src: '',
      srcColor: ''
    },
    coAuthors: [
      {
        name: 'Ajay Venkataraman',
        url: 'https://uk.linkedin.com/in/ajay-venkataraman?trk=people-guest_people_search-card'
      },
      {
        name: 'Arie E. Gozluklu',
        url: 'https://www.ariegozluklu.com'
      },
    ],
    abstract: 'We examine the impact of investment completion risk on the stock market performance of clean energy firms, isolating the completion risk channel in a theoretical model and testing the channel using project-level data from the U.S. nuclear and wind industries. We find that completion risk substantially raises the cost of capital for clean energy investments. For the nuclear industry, a 1 percentage point rise in completion risk corresponds to a 36 basis point rise in stock returns, after controlling for investment cost. In a case study, we interpret heightened regulatory scrutiny following the 1979 Three Mile Island nuclear accident as a shock to completion risk and find that completion risk strengthens as a return predictor after the accident. In Fama-MacBeth regressions, we find that nuclear completion risk is priced, particularly for firms with complex technologies. Evidence from the renewable wind industry supports our main findings from the nuclear industry.',
    bibtex: ``,
    links: []
  }
];

