// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import './css/base.css';

// Import critical pages directly
import ResearchPage from './components/ResearchPage/ResearchPage';
import TeachingPage from './components/TeachingPage/TeachingPage';

// Only lazy-load less critical pages
const lazyHomePage = () => import('./components/HomePage/HomePage');

const App = () => {
  // State to hold dynamically loaded components
  const [HomePage, setHomePage] = useState(null);

  useEffect(() => {
    // After initial render, load other pages in the background
    const timer = setTimeout(() => {
      // Load remaining components
      lazyHomePage().then((homeModule) => {
        setHomePage(() => homeModule.default);
      });
    }, 1000); // Wait 1 second after initial render before loading other pages

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-white">
        <Header />
        <Routes>
          {/* Redirect root path to research page */}
          <Route path="/" element={<Navigate to="/research" />} />
          
          {/* Use dynamic components with fallbacks */}
          <Route path="/about" element={HomePage ? <HomePage /> : <div>Loading...</div>} />
          <Route path="/research" element={<ResearchPage />} />
          <Route path="/teaching" element={<TeachingPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;


// // src/App.js
// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Header from './components/Header/Header';
// import './css/base.css';

// // Initial import for the landing page (Research)
// import ResearchPage from './components/ResearchPage/ResearchPage';

// // Define lazy components but don't load them yet
// const lazyHomePage = () => import('./components/HomePage/HomePage');
// const lazyTeachingPage = () => import('./components/TeachingPage/TeachingPage');

// const App = () => {
//   // State to hold dynamically loaded components
//   const [HomePage, setHomePage] = useState(null);
//   const [TeachingPage, setTeachingPage] = useState(null);

//   useEffect(() => {
//     // After initial render, load other pages in the background
//     const timer = setTimeout(() => {
//       // Using Promise.all to load all remaining components in parallel
//       Promise.all([
//         lazyHomePage(),
//         lazyTeachingPage()
//       ]).then(([homeModule, teachingModule]) => {
//         setHomePage(() => homeModule.default);
//         setTeachingPage(() => teachingModule.default);
//       });
//     }, 1000); // Wait 1 second after initial render before loading other pages

//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     <Router>
//       <div className="min-h-screen bg-white">
//         <Header />
//         <Routes>
//           {/* Redirect root path to research page */}
//           <Route path="/" element={<Navigate to="/research" />} />
          
//           {/* Use dynamic components with fallbacks */}
//           <Route path="/about" element={HomePage ? <HomePage /> : <div>Loading...</div>} />
//           <Route path="/research" element={<ResearchPage />} />
//           <Route path="/teaching" element={TeachingPage ? <TeachingPage /> : <div>Loading...</div>} />
//         </Routes>
//       </div>
//     </Router>
//   );
// };

// export default App;