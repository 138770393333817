import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 right-0 w-full bg-primary-700 h-16 z-50">
      <div className="max-w-[1200px] mx-auto px-4 h-full flex items-center justify-between">

        {/* Site Title */}
        <h1 className="font-serif text-display text-primary-50 font-bold">
          Rory Mullen
        </h1>

        {/* Hamburger Menu for Mobile */}
        <button 
          className="block lg:hidden p-2 text-primary-50"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <span className="sr-only">Toggle navigation</span>
          <div className="space-y-1">
            <div className="w-6 h-0.5 bg-primary-50"></div>
            <div className="w-6 h-0.5 bg-primary-50"></div>
            <div className="w-6 h-0.5 bg-primary-50"></div>
          </div>
        </button>

        {/* Navigation Links */}
        <nav 
          className={`lg:flex items-center gap-8 ${isMobileMenuOpen ? 'block' : 'hidden'} 
                      absolute lg:relative top-16 lg:top-auto left-0 lg:left-auto w-full 
                      lg:w-auto bg-primary-700 lg:bg-transparent`}
        >
          {/* Rearranged navigation menu items */}
          {[
            { to: '/', label: 'Research' },
            { to: '/teaching', label: 'Teaching' },
            { to: '/about', label: 'About Me' }
          ].map((link) => (
            <Link
              key={link.to}
              to={link.to}
              className="relative px-2 py-1 block text-primary-50 hover:text-primary-50 font-serif 
                         text-body-lg no-underline hover:no-underline group"
              onClick={() => setIsMobileMenuOpen(false)} // Close mobile menu on click
            >
              {link.label}
              <span 
                className={`
                  absolute bottom-0 left-0 w-full h-0.5 bg-accent-400
                  transition-opacity duration-200
                  ${location.pathname === link.to ? 'opacity-100' : 'opacity-0 group-hover:opacity-50'}
                `}
              />
            </Link>
          ))}

          {/* CV Link that opens in a new tab */}
          <a
            href="/documents/rory_mullen_cv.pdf" 
            target="_blank"
            rel="noopener noreferrer"
            className="relative px-2 py-1 block text-primary-50 hover:text-primary-50 font-serif 
                       text-body-lg no-underline hover:no-underline group"
          >
            CV
            <span 
              className="absolute bottom-0 left-0 w-full h-0.5 bg-accent-400
                         transition-opacity duration-200 opacity-0 group-hover:opacity-50"
            />
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;