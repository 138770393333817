export  const courses = [
  {
    id: 'ib2660',
    code: 'IB2660', 
    title: 'Intermediate Finance',
    slidesUrl: '/documents/teaching/IB2660/lecture_slides.pdf',
    status: 'Current Teaching',
    level: '10-Week Undergraduate Course',
    image: {
      src: '/figures/teaching/IB2660/portfolio_efficient_frontier/portfolio_efficient_frontier_gray.svg',
      srcColor: '/figures/teaching/IB2660/portfolio_efficient_frontier/portfolio_efficient_frontier_color.svg'
    },
    description: 'This undergraduate course builds on foundational finance concepts, deepening your understanding of investment decisions, risk, and asset pricing. We begin with a review of fundamental tools before introducing uncertainty and risk aversion. We then explore optimal portfolio construction, the Capital Asset Pricing Model, and market efficiency.\n\nNext, we examine bond markets, interest rates, and term structure, before turning to forwards, futures, and options. The final sessions focus on review and consolidation. Throughout the course, a mix of readings and problem sets will reinforce key concepts and allow you to practice new skills.\n\nStudents should be comfortable with algebra (fractions, powers, logarithms, and geometric series), calculus (differentiation and optimization of simple functions), and statistics (expected value, variance, covariance, correlation, linear regression, and properties of the normal distribution). Reviewing these topics before the course begins is highly recommended.',
    courseWebsite: 'https://moodle.warwick.ac.uk/course/view.php?id=XXXXX',
    evaluations: [
      { year: "2024/25", overall: "4.8/5", responses: "68", comment: "Rory was by far the best finance instructor I've had the opportunity to interact with. He was extremely encouraging and welcomed all questions towards the module and also extra-curriculars in the discussion of the module content." },
      { year: "2023/24", overall: "4.8/5", responses: "49", comment: "Very good at explaining things. Complicated module so his thorough approach to explaining things was super useful." },
      { year: "2022/23", overall: "4.6/5", responses: "62", comment: "Rory is honestly one of the best teachers I've had, made it very easy to understand the lessons, and is great with students as a whole. His time was greatly valuable and appreciated!" },
      { year: "2021/22", overall: "4.6/5", responses: "61", comment: "Rory is a fantastic module leader and lecturer. He explains concepts very well and is always available to answer questions in the forums or on Vevox." },
      { year: "2020/21", overall: "4.4/5", responses: "111", comment: "Rory is an excellent teacher. The man is clearly very hard working and talented in his explanations. I have no hold-backs saying he is by far the best teacher I have had at Warwick." }
    ],
    materials: [
      {
        name: 'Lecture Slides',
        url: '/documents/teaching/IB2660/lecture_slides.pdf',
        icon: 'download'
      }
    ]
  },
  {
    id: 'ib9y00',
    code: 'IB9Y00',
    title: 'Survey of the Financial System',
    slidesUrl: '/documents/teaching/IB9Y00/lecture_slides.pdf',
    status: 'Current Teaching',
    level: '10-Week Postgraduate Course',
    image: {
      src: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_gray.svg',
      srcColor: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_color.svg'
    },
    description: 'This postgraduate course provides a broad overview of financial markets and instruments. We begin with an introduction to financial systems, followed by discussions on interest rates, risk structure, and term structure. Next, we examine central banks, short-term interest rates, and interbank lending markets. The second part of the course focuses on major asset markets, including bonds, equities, mortgages, and foreign exchange. Finally, we turn to derivatives, exploring their role in risk management, financial intermediation, and market microstructure.',
    courseWebsite: 'https://moodle.warwick.ac.uk/course/view.php?id=XXXXX',
    evaluations: [
      { year: "2024/25", overall: "4.9/5", responses:"68", comment: "Rory is great at explaining things and always make sure that we get the full information about the topic to his knowledge. Kind and understanding. Always look up at the condition of his students."},
      { year: "2023/24", overall: "4.8/5", responses:"86", comment: "I believe he is the finest faculty that I was taught by in the first term. Knowledge of subject, attention to detail, motivation as well as effort were high throughout the course of the term." },
      { year: "2022/23", overall: "4.9/5", responses:"116", comment: "Rory's module was the most well-thought-out and structured module I have taken so far during my time at University. A combination of good explanations, well-structured material, and interactive seminars made this module super interesting!" },
      { year: "2021/22", overall: "4.7/5", responses:"66", comment: "Rory has put in a lot of effort to make the module as interactive and interesting as possible. He has also arranged the material in an outstanding manner which has made revision extemely covenient. Best professor!" },
      { year: "2020/21", overall: "4.8/5", responses:"86", comment: "Rory has been the best module leader for this term in terms of explaining concepts and helping students whenever contacted. The forum was a great initiative for students to connect with him and understand the concepts better if in doubt." }
    ],
    materials: [
      {
        name: 'Lecture Slides',
        url: '/documents/teaching/IB9Y00/lecture_slides.pdf',
        icon: 'presentation'
      }
    ]
  },
  {
    id: 'econ200',
    code: 'ECON200',
    title: 'Introduction to Microeconomics',
    slidesUrl: '/documents/teaching/IB9Y00/slides.pdf',
    status: 'Past Teaching',
    level: 'Undergraduate',
    image: {
      src: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_gray.svg',
      srcColor: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_color.svg'
    },
    description: 'An advanced course covering financial markets, instruments, and trading strategies, with a focus on market microstructure and institutional details.',
    courseWebsite: 'https://moodle.warwick.ac.uk/course/view.php?id=XXXXX',
    evaluations: [
      { year: 2019, overall: 4.8, comment: "Rory was great" },
    ],
    materials: [
      {
        name: 'Syllabus',
        url: '/materials/IB9Y00_syllabus.pdf',
        icon: 'document'
      },
      {
        name: 'Lecture Slides',
        url: '/materials/IB9Y00_slides.pdf',
        icon: 'presentation'
      },
      {
        name: 'Problem Sets',
        url: '/materials/IB9Y00_problems.pdf',
        icon: 'calculator'
      }
    ]
  },
  {
    id: 'econ201',
    code: 'ECON201',
    title: 'Introduction to Macroeconomics',
    slidesUrl: '/documents/teaching/IB9Y00/slides.pdf',
    status: 'Past Teaching',
    level: 'Undergraduate',
    image: {
      src: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_gray.svg',
      srcColor: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_color.svg'
    },
    description: 'An advanced course covering financial markets, instruments, and trading strategies, with a focus on market microstructure and institutional details.',
    courseWebsite: 'https://moodle.warwick.ac.uk/course/view.php?id=XXXXX',
    evaluations: [
      { year: 2019, overall: 4.8, comment: "Rory was great" },
    ],
    materials: [
      {
        name: 'Syllabus',
        url: '/materials/IB9Y00_syllabus.pdf',
        icon: 'document'
      },
      {
        name: 'Lecture Slides',
        url: '/materials/IB9Y00_slides.pdf',
        icon: 'presentation'
      },
      {
        name: 'Problem Sets',
        url: '/materials/IB9Y00_problems.pdf',
        icon: 'calculator'
      }
    ]
  },
  {
    id: 'econ301',
    code: 'ECON301',
    title: 'Intermediate Macroeconomics',
    slidesUrl: '/documents/teaching/IB9Y00/slides.pdf',
    status: 'Past Teaching',
    level: 'Undergraduate',
    image: {
      src: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_gray.svg',
      srcColor: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_color.svg'
    },
    description: 'An advanced course covering financial markets, instruments, and trading strategies, with a focus on market microstructure and institutional details.',
    courseWebsite: 'https://moodle.warwick.ac.uk/course/view.php?id=XXXXX',
    evaluations: [
      { year: 2019, overall: 4.8, comment: "Rory was great" },
    ],
    materials: [
      {
        name: 'Syllabus',
        url: '/materials/IB9Y00_syllabus.pdf',
        icon: 'document'
      },
      {
        name: 'Lecture Slides',
        url: '/materials/IB9Y00_slides.pdf',
        icon: 'presentation'
      },
      {
        name: 'Problem Sets',
        url: '/materials/IB9Y00_problems.pdf',
        icon: 'calculator'
      }
    ]
  },
  {
    id: 'econ2100',
    code: 'ECON2100',
    title: 'Business Statistics',
    slidesUrl: '/documents/teaching/IB9Y00/slides.pdf',
    status: 'Past Teaching',
    level: 'Undergraduate',
    image: {
      src: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_gray.svg',
      srcColor: '/figures/teaching/IB9Y00/fin_sys_all/fin_sys_all_color.svg'
    },
    description: 'An advanced course covering financial markets, instruments, and trading strategies, with a focus on market microstructure and institutional details.',
    courseWebsite: 'https://moodle.warwick.ac.uk/course/view.php?id=XXXXX',
    evaluations: [
      { year: "2019/20", overall: 4.8, comment: "Rory was great" },
    ],
    materials: [
      {
        name: 'Syllabus',
        url: '/materials/IB9Y00_syllabus.pdf',
        icon: 'document'
      },
      {
        name: 'Lecture Slides',
        url: '/materials/IB9Y00_slides.pdf',
        icon: 'presentation'
      },
      {
        name: 'Problem Sets',
        url: '/materials/IB9Y00_problems.pdf',
        icon: 'calculator'
      }
    ]
  }
];