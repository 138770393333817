import React from 'react';

const ContentCard = ({
  figure,
  title,
  subtitle,
  expandableContent, // Should be a React component with toggling logic passed in
  additionalMaterials,
  className = '',
}) => {
  return (
    <div 
      className={`
        bg-white 
        rounded-lg 
        border-2 border-primary-600
        shadow-md 
        transition-all duration-200 
        hover:shadow-lg
        mb-8
        w-full 
        h-auto
        ${className}
      `}
    >

      {/* Title, Subtitle */}
      <div className="px-6 pt-6 pb-0">
        {title && (
          <h3 className="text-title font-serif font-semibold text-primary-800 mb-2">
            {title}
          </h3>
        )}
        {subtitle && (
          <p className="text-body-lg font-serif text-primary-600 mb-2">
            {subtitle}
          </p>
        )}
      </div>

      {/* Figure */}
      {figure && (
        <div className="px-6 pt-0 pb-0">
          {figure}
        </div>
      )}

      {/* Expandable Content */}
      {expandableContent && (
        <div className="px-6 pt-0 pb-4 w-full">
            {expandableContent}
        </div>
      )}

      {/* Additional Materials */}
      {additionalMaterials && (
        <div className="px-6 py-2 bg-primary-600 w-full rounded-b overflow-hidden">
          <div className="flex flex-wrap gap-x-4 gap-y-0 leading-none items-start">
            {additionalMaterials}
          </div>
        </div>
      )}

    </div>
  );
};

export default ContentCard;