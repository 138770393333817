import React, { useState, useEffect } from 'react';
import ContentCard from '../Shared/ContentCard';
import ContentCardFigure from '../Shared/ContentCardFigure';
import { courses } from './TeachingData';
import { useExpandableContent, DropdownTrigger } from '../Shared/ExpandableContent';

const TeachingPage = () => {
  const { expandedContent, toggleContent } = useExpandableContent();
  const [imagesLoaded, setImagesLoaded] = useState(false);
  
  // Preload all course images to reduce layout shifts
  useEffect(() => {
    const imageUrls = courses
      .filter(course => course.status === "Current Teaching" && course.image)
      .map(course => course.image.src)
      .concat(
        courses
          .filter(course => course.status === "Current Teaching" && course.image && course.image.srcColor)
          .map(course => course.image.srcColor)
      );
    
    if (imageUrls.length === 0) {
      setImagesLoaded(true);
      return;
    }
    
    let loadedCount = 0;
    
    imageUrls.forEach(url => {
      const img = new Image();
      img.onload = () => {
        loadedCount++;
        if (loadedCount === imageUrls.length) {
          setImagesLoaded(true);
        }
      };
      img.onerror = () => {
        loadedCount++;
        if (loadedCount === imageUrls.length) {
          setImagesLoaded(true);
        }
      };
      img.src = url;
    });
  }, []);

  return (
    <div className="max-w-[800px] mx-auto pt-16">
      <main className="p-8">
        
        {/* Current Teaching Section */}
        <section id="current-teaching">
          <h2 className="font-serif text-display font-bold mb-6 text-primary-800">Current Teaching</h2> 
          
          <div className={`transition-opacity duration-300 ${imagesLoaded ? 'opacity-100' : 'opacity-70'}`}>
            {courses
              .filter(course => course.status === "Current Teaching")
              .map(course => (
              <ContentCard
                key={course.id}
                figure={course.image && (
                  <a href={course.slidesUrl} target="_blank" rel="noopener noreferrer">
                    <ContentCardFigure
                      src={course.image.src}
                      srcColor={course.image.srcColor}
                      alt={`Figure from ${course.title}`}
                    />
                  </a>
                )}
                title={
                  <a 
                    href={course.slidesUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="link-prominent"
                  >
                    {course.title}
                  </a>
                }
                subtitle={
                  <p className="font-serif text-body-lg">
                    {course.level}
                  </p>
                }
                expandableContent={
                  <>
                    <DropdownTrigger
                      onClick={() => toggleContent(course.id, 'description')}
                      isActive={expandedContent[course.id] === 'description'}
                      className="link-prominent font-sans text-body"
                    >
                    <span className="link-prominent font-serif text-body-lg">
                      Description
                    </span>
                    </DropdownTrigger>
                    <DropdownTrigger
                      onClick={() => toggleContent(course.id, 'evaluations')}
                      isActive={expandedContent[course.id] === 'evaluations'}
                      className="link-prominent font-sans text-body"
                    >
                    <span className="link-prominent font-serif text-body-lg">
                      Evaluations
                    </span>
                    </DropdownTrigger>
                    {expandedContent[course.id] === 'description' && (
                      <div className="mt-2 mb-2 bg-primary-100 p-4 rounded-lg border border-primary-400">
                        {course.description.split("\n").map((para, index) => (
                          <p key={index} className="mb-0">{para.trim()}</p>
                        ))}
                      </div>
                    )}
                    {expandedContent[course.id] === 'evaluations' && (
                      <ul className="mt-2 mb-2 bg-primary-100 p-4 rounded-lg border border-primary-400 space-y-2 list-disc list-inside">
                        {course.evaluations.map((evaluation) => (
                          <li key={evaluation.year}>
                            <strong>{evaluation.year}:</strong> Rated {evaluation.overall} by {evaluation.responses} Students — &#8220;{evaluation.comment}&#8221;
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                }
                additionalMaterials={
                  <div className="ml-0 flex flex-wrap gap-4">
                    {course.materials && course.materials.map(link => (
                      <a 
                        key={link.name} 
                        href={link.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="link-materials text-body font-sans"
                      >
                        {link.name}
                      </a>
                    ))}
                  </div>
                }
              />
            ))}
          </div>
        </section>

        {/* Your commented out Past Teaching Section remains the same */}
      </main>
    </div>
  );
};

export default TeachingPage;



// import React from 'react';
// import ContentCard from '../Shared/ContentCard';
// import ContentCardFigure from '../Shared/ContentCardFigure';
// import { courses } from './TeachingData'; // Importing course data
// import { useExpandableContent, DropdownTrigger } from '../Shared/ExpandableContent'; // Custom hook for expandable content

// const TeachingPage = () => {
//   const { expandedContent, toggleContent } = useExpandableContent(); // Handles which content is expanded

//   return (
//     <div className="max-w-[800px] mx-auto pt-16">  {/* Main container with padding */}
//       <main className="p-8">
        
//         {/* Current Teaching Section */}
//         <section id="current-teaching">
//           <h2 className="font-serif text-display font-bold mb-6 text-primary-800">Current Teaching</h2> 
          
//           {/* Mapping through each course in the imported 'courses' data */}
//           {courses
//             .filter(course => course.status === "Current Teaching")
//             .map(course => (
//             <ContentCard
//               key={course.id}
//               figure={course.image && (
//                 <a href={course.slidesUrl} target="_blank" rel="noopener noreferrer">
//                   <ContentCardFigure
//                     src={course.image.src}
//                     srcColor={course.image.srcColor}
//                     alt={`Figure from ${course.title}`}
//                   />
//                 </a>
//               )}
//               title={
//                 <a 
//                   href={course.slidesUrl} 
//                   target="_blank" 
//                   rel="noopener noreferrer"
//                   className="link-prominent"
//                 >
//                   {course.title}
//                 </a>
//               }
//               subtitle={
//                 <p className="font-serif text-body-lg">
//                   {course.level}
//                 </p>
//               }
//               expandableContent={
//                 <>
//                   <DropdownTrigger
//                     onClick={() => toggleContent(course.id, 'description')}
//                     isActive={expandedContent[course.id] === 'description'}
//                     className="link-prominent font-sans text-body"
//                   >
//                   <span className="link-prominent font-serif text-body-lg">
//                     Description
//                   </span>
//                   </DropdownTrigger>
//                   <DropdownTrigger
//                     onClick={() => toggleContent(course.id, 'evaluations')}
//                     isActive={expandedContent[course.id] === 'evaluations'}
//                     className="link-prominent font-sans text-body"
//                   >
//                   <span className="link-prominent font-serif text-body-lg">
//                     Evaluations
//                   </span>
//                   </DropdownTrigger>
//                   {expandedContent[course.id] === 'description' && (
//                     <div className="mt-2 mb-2 bg-primary-100 p-4 rounded-lg border border-primary-400">
//                       {course.description.split("\n").map((para, index) => (
//                         <p key={index} className="mb-4">{para.trim()}</p>
//                       ))}
//                     </div>
//                   )}
//                   {expandedContent[course.id] === 'evaluations' && (
//                     <ul className="mt-2 mb-2 bg-primary-100 p-4 rounded-lg border border-primary-400 space-y-2 list-disc list-inside">
//                       {course.evaluations.map((evaluation) => (
//                         <li key={evaluation.year}>
//                           <strong>{evaluation.year}:</strong> Rated {evaluation.overall} by {evaluation.responses} Students — &#8220;{evaluation.comment}&#8221;
//                         </li>
//                       ))}
//                     </ul>
//                   )}
//                 </>
//               }
//               additionalMaterials={
//                 <div className="ml-0 flex flex-wrap gap-4">
//                   {course.materials && course.materials.map(link => (
//                     <a 
//                       key={link.name} 
//                       href={link.url} 
//                       target="_blank" 
//                       rel="noopener noreferrer"
//                       className="link-materials text-body font-sans"
//                     >
//                       {link.name}
//                     </a>
//                   ))}
//                 </div>
//               }
//             />
//           ))}
//         </section>


//         {/* Past Teaching Section */}
// {/*        <section id="past-teaching" className="mt-12">
//           <h2 className="font-serif text-display font-bold mb-6 text-primary-800">Past Teaching</h2>

//           {courses
//             .filter(course => course.status === "Past Teaching")
//             .map(course => (
//               <ContentCard
//                 key={course.id}
//                 title={
//                   <span className="text-primary-600 font-bold">{course.title}</span>
//                 }
//                 subtitle={
//                   <p className="font-serif text-body-lg">
//                     {course.level}
//                   </p>
//                 }
//               expandableContent={
//                 <>
//                   <DropdownTrigger
//                     onClick={() => toggleContent(course.id, 'description')}
//                     isActive={expandedContent[course.id] === 'description'}
//                     className="link-prominent font-sans text-body"
//                   >
//                   <span className="link-prominent font-serif text-body-lg">
//                     Description
//                   </span>
//                   </DropdownTrigger>
//                   <DropdownTrigger
//                     onClick={() => toggleContent(course.id, 'evaluations')}
//                     isActive={expandedContent[course.id] === 'evaluations'}
//                     className="link-prominent font-sans text-body"
//                   >
//                   <span className="link-prominent font-serif text-body-lg">
//                     Evaluations
//                   </span>
//                   </DropdownTrigger>
//                   {expandedContent[course.id] === 'description' && (
//                     <div className="mt-2 mb-2 bg-primary-100 p-4 rounded-lg border border-primary-400">
//                       <p>{course.description}</p>
//                     </div>
//                   )}
//                   {expandedContent[course.id] === 'evaluations' && (
//                     <ul className="mt-2 mb-2 bg-primary-100 p-4 rounded-lg border border-primary-400 space-y-2 list-disc list-inside">
//                       {course.evaluations.map((evaluation) => (
//                         <li key={evaluation.year}>
//                           <strong>{evaluation.year}:</strong> {evaluation.overall} — {evaluation.comment}
//                         </li>
//                       ))}
//                     </ul>
//                   )}
//                 </>
//               }
//               additionalMaterials={
//                 <div className="ml-0 flex flex-wrap gap-4">
//                   {course.materials && course.materials.map(link => (
//                     <a 
//                       key={link.name} 
//                       href={link.url} 
//                       target="_blank" 
//                       rel="noopener noreferrer"
//                       className="link-materials text-body font-sans"
//                     >
//                       {link.name}
//                     </a>
//                   ))}
//                 </div>
//               }
//               />
//             ))}
//         </section>*/}
//       </main>
//     </div>
//   );
// };

// export default TeachingPage;
