import React, { useState, useEffect } from 'react';

const ContentCardFigure = ({ src, srcColor, alt }) => {
  const hasDualVersions = src && srcColor;
  const [aspectRatio, setAspectRatio] = useState(0.75); // Default aspect ratio as fallback
  const [isLoaded, setIsLoaded] = useState(false);

  // Preload images to get dimensions
  useEffect(() => {
    if (src) {
      const img = new Image();
      img.onload = () => {
        setAspectRatio(img.height / img.width);
        setIsLoaded(true);
      };
      img.src = src;
    }
  }, [src]);

  return (
    <div className="mb-0 relative" style={{ 
      paddingTop: `${aspectRatio * 100}%`, // Reserve space based on aspect ratio
      minHeight: isLoaded ? 'auto' : '300px', // Minimum height before image loads
      width: '100%'
    }}>
      <figure className="m-0 p-0 absolute top-0 left-0 w-full h-full">
        {/* Base Image */}
        <img 
          src={src}
          alt={alt}
          className={`w-full h-full object-contain transition-opacity duration-300 ${
            hasDualVersions ? 'hover:opacity-0' : ''
          }`}
          style={{
            border: '0',
            outline: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            position: 'relative',
            zIndex: '1'
          }}
        />

        {/* Hover Image */}
        {hasDualVersions && (
          <img 
            src={srcColor}
            alt={alt}
            className="w-full h-full object-contain absolute top-0 left-0 opacity-0 transition-opacity duration-300 hover:opacity-100"
            style={{
              border: '0',
              outline: 'none',
              boxShadow: 'none',
              backgroundColor: 'transparent',
              zIndex: '2'
            }}
          />
        )}
      </figure>
    </div>
  );
};

export default ContentCardFigure;
