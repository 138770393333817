import React from 'react';
import { useState } from 'react';
import ContentCard from '../Shared/ContentCard';
import ContentCardFigure from '../Shared/ContentCardFigure';
import { articles } from './ResearchData'; // Importing data for research articles
import { useExpandableContent, DropdownTrigger } from '../Shared/ExpandableContent'; // Custom hook for expandable content

const ResearchPage = () => {
  const { expandedContent, toggleContent } = useExpandableContent(); // Manages which article's content is expanded
  const [copiedBibtex, setCopiedBibtex] = useState(null);
  
  // Function to copy BibTeX to clipboard
  const copyBibtexToClipboard = (id, bibtex) => {
    navigator.clipboard.writeText(bibtex).then(() => {
      setCopiedBibtex(id);
      // Reset the "Copied!" message after 2 seconds
      setTimeout(() => setCopiedBibtex(null), 2000);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div className="max-w-[800px] mx-auto pt-16"> {/* Main container with a maximum width */}
      <main className="p-8">

        {/* Working Papers Section */}
        <section id="working-papers">
          <h2 className="text-display font-bold mb-6">Working Papers</h2> {/* Section Title */}
          
          {/* Mapping through all articles and rendering their content */}
          {articles
            .filter(article => article.status === "Working Paper")
            .map(article => (
              <ContentCard
                key={article.id}
                figure={article.image && (
                  <a href={article.paperUrl} target="_blank" rel="noopener noreferrer">
                    <ContentCardFigure
                      src={article.image.src}
                      srcColor={article.image.srcColor}
                      alt={`Figure from ${article.title}`}
                    />
                  </a>
                )}
                title={
                  <a 
                    href={article.paperUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="link-prominent text-primary-700"
                  >
                    {article.title}
                  </a>
                }
                subtitle={
                  article.coAuthors && (
                    <>
                      <span className="font-normal">with </span>
                      {article.coAuthors.map((author, index) => {
                        const isLast = index === article.coAuthors.length - 1;

                        return (
                          <React.Fragment key={author.name}>
                            {index > 0 && (isLast ? (article.coAuthors.length > 2 ? ', and ' : ' and ') : ', ')}
                            {author.url ? (
                              <a
                                href={author.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-prominent"
                              >
                                {author.name}
                              </a>
                            ) : (
                              author.name
                            )}
                          </React.Fragment>
                        );
                      })}
                    </>
                  )
                }

                expandableContent={
                  <>
                    <DropdownTrigger
                      onClick={() => toggleContent(article.id, 'abstract')}
                      isActive={expandedContent[article.id] === 'abstract'}
                      className="link-prominent"
                    >
                      <span className="link-prominent font-serif text-body-lg">
                        Abstract
                      </span>
                    </DropdownTrigger>
                    
                    {expandedContent[article.id] === 'abstract' && (
                      <div className="mt-2 mb-2 bg-secondary-100 p-4 rounded-lg border border-secondary-400">
                        <p>{article.abstract}</p>
                      </div>
                    )}
                  </>
                }
                additionalMaterials={
                  <div className="ml-0 flex flex-wrap gap-x-4 gap-y-0.5">
                    {/* Paper Link */}
                    {article.paperUrl && (
                      <a 
                        href={article.paperUrl} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="link-materials text-body font-sans"
                      >
                        Paper
                      </a>
                    )}
                    
                    {/* Other Links */}
                    {article.links && article.links.map(link => (
                      <a 
                        key={link.name} 
                        href={link.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="link-materials text-body font-sans"
                      >
                        {link.name}
                      </a>
                    ))}
                    
                    {/* BibTeX Link */}
                    <button
                      onClick={() => copyBibtexToClipboard(article.id, article.bibtex)}
                      className="link-materials text-body font-sans bg-transparent border-none cursor-pointer p-0 relative"
                    >
                      {copiedBibtex === article.id ? 'Copied!' : 'BibTeX'}
                    </button>
                  </div>
                }
              />
            ))}
          </section>

        {/* Works in Progress Section */}
        <section id="works-in-progress" className="mt-12">
          <h2 className="font-serif text-display font-bold mb-6 text-primary-800">
            Works in Progress
          </h2>

          <div className="grid gap-6">
            {articles.filter(article => article.status === "Work in Progress").length > 0 ? (
              articles
                .filter(article => article.status === "Work in Progress")
                .map(article => (
                  <ContentCard
                    key={article.id}
                    title={
                      <span className="text-primary-600">{article.title}</span>
                    }
                    subtitle={
                      article.coAuthors && (
                        <>
                          <span className="font-normal">with </span>
                          {article.coAuthors.map((author, index) => {
                            const isLast = index === article.coAuthors.length - 1;

                            return (
                              <React.Fragment key={author.name}>
                                {index > 0 && (isLast ? (article.coAuthors.length > 2 ? ', and ' : ' and ') : ', ')}
                                {author.url ? (
                                  <a
                                    href={author.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="link-prominent"
                                  >
                                    {author.name}
                                  </a>
                                ) : (
                                  author.name
                                )}
                              </React.Fragment>
                            );
                          })}
                        </>
                      )
                    }
                    expandableContent={
                      <>
                        <div className="mt-1 mb-2 bg-secondary-100 p-4 rounded-lg border border-secondary-400">
                          <p>{article.abstract}</p>
                        </div>
                      </>
                    }
                    additionalMaterials={
                      <div className="ml-0 flex flex-wrap gap-4">
                        {/* Paper Link */}
                        {article.paperUrl && (
                          <a 
                            href={article.paperUrl} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="link-materials text-body font-sans"
                          >
                            Paper
                          </a>
                        )}
                        
                        {/* Other Links */}
                        {article.links && article.links.map(link => (
                          <a 
                            key={link.name} 
                            href={link.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="link-materials text-body font-sans"
                          >
                            {link.name}
                          </a>
                        ))}
                        
                        {/* BibTeX Link */}
                        {article.bibtex && (
                          <button
                            onClick={() => copyBibtexToClipboard(article.id, article.bibtex)}
                            className="link-materials text-body font-sans bg-transparent border-none cursor-pointer p-0"
                          >
                            {copiedBibtex === article.id ? 'Copied!' : 'BibTeX'}
                          </button>
                        )}
                      </div>
                    }
                  />
                ))
            ) : (
              <ContentCard
                title={
                  <span className="text-primary-600 font-bold">
                    Coming Soon
                  </span>
                }
                subtitle={
                  <span className="text-primary-600">
                    Check back soon for updates on my latest research projects.
                  </span>
                }
              />
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

export default ResearchPage;